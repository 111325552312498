import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgLaunchCover from "../../assets/images/journey/launch/launch-cover.png"

const LaunchPage = () => (
  <Layout>
    <SEO title="Launch" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #fff"}}>
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgLaunchCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                    LAUNCH PHASE PLAN
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  EMM will begin its journey to Mars from the Tanegashima island in Japan. The mission employs a 30-day launch period for the Hope Probe, opening on July 14 and ending on August 12, 2020. On the launch day, the Hope Probe will take-off on a Mitsubishi H-IIA rocket toward the east, on a trajectory that takes it over the Pacific Ocean.
                  </p>
                  <p>
                  As the rocket accelerates away from the Earth, the solid rocket boosters are expended, followed by jettisoning the fairing once it is no longer needed to protect the Hope Probe from the Earth’s atmosphere. 
                  </p>
                  <p>
                  Once the first stage is completed, the rocket is jettisoned and put into the Earth’s orbit. It stays in the Earth’s orbit until the exact alignment with Mars is achieved after which it is reignited to push it on a trajectory towards Mars. With the right direction and velocity of 11km/s, the upper stage gently deploys the Hope Probe.
                  </p>
                </div>
            </div>  
                
          </div>  
      </section>
    </main>
  </Layout>
)

export default LaunchPage
